import { createStore } from 'vuex';

export default createStore({
  state: {
    isUserLogin: false,
    isLoading: false,
  },
  getters: {},
  mutations: {
    userLogin(state) {
      state.isUserLogin = true;
    },
    userLogout(state) {
      state.isUserLogin = false;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {},
  modules: {},
});
