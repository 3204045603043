import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { BASE_URL } from '@/constants/settings';
import store from '@/store';

export interface CommonResponse<T> {
  error: boolean;
  message?: string;
  data?: T;
}

export const get = async (uri: string): Promise<CommonResponse<any>> => {
  let response: AxiosResponse<CommonResponse<any>>;
  try {
    store.commit('setLoading', true);
    response = <AxiosResponse<CommonResponse<any>>>await axios.get(
      BASE_URL + uri,
      {
        withCredentials: true,
        timeout: 5000,
      }
    );
    store.commit('setLoading', false);
    return response.data;
  } catch (e) {
    store.commit('setLoading', false);
    if (!isAxiosError<CommonResponse<any>>(e)) {
      return {
        error: true,
        message: '',
      };
    }

    if (e.response?.status) {
      if (e.response.status === 521 || e.response.status === 502) {
        return {
          error: true,
          message: '',
        };
      }
    }

    if (e.response?.data) {
      return e.response.data;
    }

    return {
      error: true,
      message: '',
    };
  }
};

export const post = async (
  uri: string,
  body: any
): Promise<CommonResponse<any>> => {
  try {
    store.commit('setLoading', true);

    const response = <AxiosResponse<CommonResponse<any>>>await axios.post(
      BASE_URL + uri,
      body,
      {
        withCredentials: true,
        timeout: 5000,
      }
    );
    store.commit('setLoading', false);
    return response.data;
  } catch (e) {
    store.commit('setLoading', false);
    if (!isAxiosError<CommonResponse<any>>(e)) {
      return {
        error: true,
        message: '',
      };
    }

    if (e.response?.status) {
      if (e.response.status === 521 || e.response.status === 502) {
        return {
          error: true,
          message: '',
        };
      }
    }

    if (e.response?.data) {
      return e.response.data;
    }

    return {
      error: true,
      message: '',
    };
  }
};
