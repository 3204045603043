<template>
  <div class="wrapper">
    <SkyonLoader />
    <SideBar />
    <router-view />
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue';
import SkyonLoader from '@/components/Lottie/SkyonLoader.vue';
export default {
  components: {
    SideBar,
    SkyonLoader,
  },
};
</script>
