<template>
  <div id="sidebar" class="sidebar">
    <div>
      <div class="ml-[40px] mb-[24px]">
        <img src="@/assets/logos/logo_header.svg" alt="스카이온" />
      </div>
      <div
        v-for="(menuItem, index) in menuList"
        :key="index"
        :class="[
          this.$route.path.startsWith(menuItem.path)
            ? 'bg-sk-box001 text-sk-primary text-body2-bold'
            : 'bg-sk-box002',
        ]"
        class="item"
        @click="this.$router.push(menuItem.path)"
      >
        <img
          :src="
            this.$route.path.startsWith(menuItem.path)
              ? menuItem.icon_on
              : menuItem.icon
          "
          :alt="menuItem.title"
        />
        <p>{{ menuItem.title }}</p>
      </div>
    </div>
    <div @click="logOut" class="item">
      <img src="@/assets/icons/logout.svg" alt="로그아웃" />
      <p>로그아웃</p>
    </div>
  </div>
</template>

<script>
import { logout } from '@/apis/user';
export default {
  name: 'SideBar',
  data() {
    return {
      menuList: [
        {
          title: '선생님 회원',
          path: '/teacher',
          icon: require('@/assets/icons/person.svg'),
          icon_on: require('@/assets/icons/person_on.svg'),
        },
        {
          title: '학생 회원',
          path: '/student',
          icon: require('@/assets/icons/person.svg'),
          icon_on: require('@/assets/icons/person_on.svg'),
        },
        {
          title: '수업방',
          path: '/class',
          icon: require('@/assets/icons/class.svg'),
          icon_on: require('@/assets/icons/class_on.svg'),
        },
        {
          title: '수강신청 / 매칭',
          path: '/enroll',
          icon: require('@/assets/icons/calendar.svg'),
          icon_on: require('@/assets/icons/calendar_on.svg'),
        },
        {
          title: '교재 관리',
          path: '/book',
          icon: require('@/assets/icons/book.svg'),
          icon_on: require('@/assets/icons/book_on.svg'),
        },
        {
          title: '선생님 정산 관리',
          path: '/money/teacher',
          icon: require('@/assets/icons/refund.svg'),
          icon_on: require('@/assets/icons/refund_on.svg'),
        },
        {
          title: '학생 결제 관리',
          path: '/money/student',
          icon: require('@/assets/icons/money.svg'),
          icon_on: require('@/assets/icons/money_on.svg'),
        },
      ],
    };
  },
  methods: {
    async logOut() {
      const logoutRes = await logout();
      if (logoutRes.error) {
        window.alert(logoutRes.message);
        return;
      }
      this.$store.commit('userLogout');
      this.$router.push('/');
    },
  },
};
</script>
