<template>
  <div
    class="login bg-sk-box004 h-screen w-full flex flex-col justify-center items-center"
  >
    <div class="px-[20px] flex flex-col justify-center items-center">
      <img alt="스카이온" src="../assets/logos/logo_login.svg" />
      <form
        @submit.prevent="loginSubmit()"
        class="flex flex-col justify-center items-center mt-[50px]"
      >
        <div class="flex flex-col">
          <label for="id" class="text-body2-bold pl-[10px] mb-[12px]"
            >아이디</label
          >
          <input
            id="id"
            type="email"
            placeholder="아이디를 입력해 주세요"
            maxlength="20"
            v-model="id"
            required
            class="mb-[20px] min-w-[350px] h-[60px] px-[20px] py-[18px] text-body2-medium rounded-[10px] bg-sk-box002 placeholder:text-sk-disabled focus:outline-none focus:border focus:border-sk-primary"
          />
        </div>
        <div class="flex flex-col">
          <label for="password" class="text-body2-bold pl-[10px] mb-[12px]"
            >비밀번호</label
          >
          <input
            id="password"
            type="password"
            placeholder="비밀번호를 입력해 주세요"
            minlength="6"
            maxlength="20"
            required
            v-model="password"
            class="mb-[50px] min-w-[350px] h-[60px] px-[20px] py-[18px] text-body2-medium rounded-[10px] bg-sk-box002 placeholder:text-sk-disabled focus:outline-none focus:border focus:border-sk-primary"
          />
        </div>
        <button
          type="submit"
          class="min-w-[350px] h-[55px] rounded-[10px] bg-sk-primary focus:bg-sk-primaryPressed text-sk-default2 text-body2-bold"
        >
          로그인
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { login } from '@/apis/user';
export default {
  name: 'LoginView',
  data() {
    return {
      id: '',
      password: '',
    };
  },
  mounted() {
    document.getElementById('sidebar').style.display = 'none';
  },
  unmounted() {
    document.getElementById('sidebar').style.display = 'block';
  },
  methods: {
    async loginSubmit() {
      const loginRes = await login({ email: this.id, password: this.password });
      if (!loginRes.error) {
        this.$router.push('/teacher');
      } else {
        alert(loginRes.message);
      }
    },
  },
};
</script>
