import {
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from 'vue-router';
import LoginView from '../views/LoginView.vue';
import store from '../store';
import { profile } from '@/apis/user';

const requireAuth =
  () =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function
  ) => {
    store.commit('setLoading', true);
    let profileRes = await profile();
    if (profileRes.error) {
      store.commit('userLogout');
      store.commit('setLoading', false);
      return next('/');
    } else {
      store.commit('userLogin');
      store.commit('setLoading', false);
      return next();
    }
  };
const noRequireAuth =
  () =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function
  ) => {
    store.commit('setLoading', true);
    let profileRes = await profile();
    if (profileRes.error) {
      store.commit('userLogout');
      store.commit('setLoading', false);
      return next();
    } else {
      store.commit('userLogin');
      store.commit('setLoading', false);
      return next('/teacher');
    }
  };
const commonAuth =
  () =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function
  ) => {
    store.commit('setLoading', true);
    let profileRes = await profile();
    if (profileRes.error) {
      store.commit('userLogout');
    } else {
      store.commit('userLogin');
    }
    store.commit('setLoading', false);
    return next();
  };
const routes = [
  {
    path: '/',
    name: 'LoginView',
    beforeEnter: noRequireAuth(),
    component: LoginView,
  },
  {
    path: '/404',
    name: 'NotFoundView',
    beforeEnter: commonAuth(),
    component: () =>
      import(/* webpackChunkName: "notFound" */ '../views/NotFoundView.vue'),
  },
  {
    path: '/teacher',
    name: 'TeacherView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/TeacherView.vue'),
  },
  {
    path: '/teacher/detail',
    name: 'TeacherDetailView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/TeacherDetailView.vue'),
  },
  {
    path: '/student',
    name: 'StudentView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/StudentView.vue'),
  },
  {
    path: '/student/detail',
    name: 'StudentDetailView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/StudentDetailView.vue'),
  },
  {
    path: '/class',
    name: 'ClassView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/ClassView.vue'),
  },
  {
    path: '/class/detail',
    name: 'ClassDetailView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/ClassDetailView.vue'),
  },
  {
    path: '/enroll',
    name: 'EnrollView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/EnrollView.vue'),
  },
  {
    path: '/enroll/detail',
    name: 'EnrollDetailView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/EnrollDetailView.vue'),
  },
  {
    path: '/enroll/submit',
    name: 'EnrollSubmitView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/EnrollSubmitView.vue'),
  },
  {
    path: '/book',
    name: 'BookView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/BookView.vue'),
  },
  {
    path: '/book/detail',
    name: 'BookDetailView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/BookDetailView.vue'),
  },
  {
    path: '/money/teacher',
    name: 'MoneyTeacherView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/MoneyTeacherView.vue'),
  },
  {
    path: '/money/student',
    name: 'MoneyStudentView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/MoneyStudentView.vue'),
  },
  {
    path: '/tempclass',
    name: 'TempClassView',
    beforeEnter: requireAuth(),
    component: () =>
      import(/* webpackChunkName: "skyon" */ '../views/TempClassView.vue'),
  },
  // not found redirect
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
