<script setup>
import { ref } from 'vue';
import { LottieAnimation } from 'lottie-web-vue';
import SkyonLoaderJSON from '@/assets/lottie/SkyonLoader.json';
import store from '@/store';
const anim = ref();
</script>
<template>
  <div
    v-if="store.state.isLoading"
    class="fixed top-0 left-0 z-[110] w-full h-screen flex justify-center items-center bg-sk-box001/60"
  >
    <LottieAnimation
      :animation-data="SkyonLoaderJSON"
      :auto-play="true"
      :loop="true"
      :speed="1"
      ref="anim"
      class="w-[360px] h-[360px]"
    />
  </div>
</template>
