import { CommonResponse, get, post } from './common';

// (01)

/**
 * user/login (02)
 */
interface LoginRequest {
  email: string;
  password: string;
}
export const login = async (
  body: LoginRequest
): Promise<CommonResponse<null>> => {
  return await post('user/login', body);
};

/**
 * user/logout (03)
 */
export const logout = async (): Promise<CommonResponse<null>> => {
  return await get('user/logout');
};

/**
 * user/profile (04)
 */
interface ProfileResponse {
  email: string;
}
export const profile = async (): Promise<CommonResponse<ProfileResponse>> => {
  return await get('user/profile');
};
